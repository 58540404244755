import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header='Contact Me'
          subheader="Like what I do or want to tell me about how the strategy pattern doesn't exist in functional programming? I'd love to hear from you either way. Drop me a message in the form below and I'll get back to you."
        />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        {/* <Commitment /> */}
        <Divider />
        {/* <ContactInfo /> */}
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
